<template>
  <v-row class="match-height">
    <v-col cols="12" md="12">
      <v-data-table
        :headers="table1.headers"
        :items="table1.items"
        :loading="table1.loading"
        hide-default-footer
        :page.sync="pageTable1"
        :items-per-page="table1.itemsPerPage"
        dense
        class="elevation-1"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title class="font-weight-bold">Master Barang</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-dialog v-model="table1.dialog" max-width="700px">
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on" @click="openDialog()"> Tambah </v-btn>
              </template>
              <v-card>
                <v-card-title>
                  <span class="text-h5">{{ formTitle }} Barang</span>
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12">
                        <!-- <div style="border: 1px solid grey; padding: 5px"> -->
                        <v-img
                          max-width="600"
                          max-height="400"
                          v-if="table1.itemEdit.previewImage"
                          :src="table1.itemEdit.previewImage"
                        ></v-img>
                        <!-- </div> -->
                        <input
                          type="file"
                          label="Pilih Foto"
                          accept="image/png, image/jpeg"
                          ref="foto"
                          @change="onFileChange()"
                        />
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                          :rules="[rules.required]"
                          hide-details
                          v-model="table1.itemEdit.nama_barang"
                          label="Nama *"
                          outlined
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-textarea
                          filled
                          :rules="[rules.required]"
                          label="Detail *"
                          auto-grow
                          outlined
                          v-model="table1.itemEdit.detail"
                          hide-details
                        ></v-textarea>
                      </v-col>
                      <v-col cols="12">
                        <v-textarea
                          filled
                          :rules="[rules.required]"
                          label="Deskripsi *"
                          auto-grow
                          outlined
                          hide-details
                          v-model="table1.itemEdit.deskripsi"
                        ></v-textarea>
                      </v-col>
                      <v-col cols="6">
                        <v-text-field
                          type="number"
                          :rules="[rules.required]"
                          outlined
                          hide-details
                          v-model="table1.itemEdit.harga"
                          label="harga *"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="6" style="font-weight: bold; padding-top: 30px; color: red">
                        {{ formatMoney(table1.itemEdit.harga) }}
                      </v-col>
                      <v-col cols="6">
                        <v-text-field
                          type="number"
                          :rules="[rules.required]"
                          outlined
                          hide-details
                          v-model="table1.itemEdit.harga_jual"
                          label="harga Jual *"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="6" style="font-weight: bold; padding-top: 30px; color: red">
                        {{ formatMoney(table1.itemEdit.harga_jual) }}
                      </v-col>
                      <v-col cols="6">
                        <v-text-field
                          outlined
                          type="number"
                          :rules="[rules.required]"
                          hide-details
                          v-model="table1.itemEdit.qty"
                          label="Jumlah *"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="6" style="font-weight: bold; padding-top: 30px; color: red">
                        {{ formatMoney(table1.itemEdit.qty) }}
                      </v-col>
                      <v-col cols="12" v-if="table1.editedIndex > -1">
                        <v-select
                          label="Status"
                          :items="table1.statuss2"
                          outlined
                          dense
                          v-model="table1.itemEdit.status"
                        ></v-select>
                      </v-col>
                      <!-- <v-col cols="12">
                        <v-text-field v-model="table1.itemEdit.status" label="Status *"></v-text-field>
                      </v-col> -->
                    </v-row>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" @click="save()"> Simpan </v-btn>
                  <v-btn color="secondary" @click="close()"> Batal </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>

            <!-- <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card>
                <v-card-title class="text-h5">Apakah yakin akan menghapus berita ini?</v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="secondary" @click="closeDelete">Batal</v-btn>
                  <v-btn color="error" @click="deleteItemConfirm">Yakin</v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog> -->
          </v-toolbar>
          <v-form class="ml-4">
            <v-row>
              <v-col cols="1" md="1">
                <label class="font-weight-black" for="firstname">Filter</label>
              </v-col>
              <v-col cols="3" md="3">
                <v-text-field
                  outlined
                  dense
                  v-model="table1.search"
                  label="Nama"
                  @change="optionChange()"
                ></v-text-field>
              </v-col>
              <v-col cols="3" md="3">
                <v-select
                  label="Status"
                  :items="table1.statuss"
                  outlined
                  dense
                  v-model="table1.status"
                  @change="optionChange()"
                ></v-select>
              </v-col>
              <v-col cols="2">
                <v-btn class="primary" small @click="optionChange()">Lihat</v-btn>
              </v-col>
            </v-row>
          </v-form>
        </template>
        <template v-slot:item.foto="{ item }">
          <v-img
            style="margin: 5px"
            max-width="200"
            max-height="150"
            :src="`data:image/png;base64, ${item.foto}`"
            :lazy-src="require('@/assets/images/logos/logo.png')"
          ></v-img>
        </template>
        <!-- <template v-slot:item.isi="{ item }">
          {{ item.isi.substring(0, 50) + ' ...' }}
        </template> -->
        <template v-slot:item.harga="{ item }">
          {{ formatMoney(item.harga) + '/ ' + formatMoney(item.harga_jual) }}
        </template>
        <template v-slot:item.aksi="{ item }">
          <v-icon class="mr-2 bedit" @click="openDialogEdit(item)"> {{ icons.mdiPencil }} </v-icon>
          <v-icon class="mr-2 bdelete" @click="openDialogDelete(item)"> {{ icons.mdiDelete }} </v-icon>
        </template>
        <template v-slot:no-data>
          <v-btn color="primary" small @click="getTable1"> Reload </v-btn>
        </template>
      </v-data-table>
      <div class="px-4 py-4 white">
        <v-row>
          <v-col cols="6">
            <!-- <div style="display: inline-block">Jumlah per halaman</div> -->
            <v-select
              label="Jumlah per halaman"
              :items="table1.length_menus"
              hide-details
              outlined
              dense
              v-model="table1.itemsPerPage"
              @change="optionChange()"
            ></v-select>
          </v-col>
          <v-col cols="6">
            <v-pagination v-model="pageTable1" :length="table1.pageCount" :total-visible="3"></v-pagination>
          </v-col>
        </v-row>
      </div>
      <!-- <v-dialog max-width="1024px" v-model="table1.dialogDetail">
        <v-card>
          <v-card-title>
            <span class="text-h5">Daftar Iuran Arisan</span>
          </v-card-title>
          <v-card-text>
            <v-simple-table dense style="width: 100%" v-if="table3.pasienSelected !== null">
              <tbody>
                <tr>
                  <td><b>Foto</b></td>
                  <td>:</td>
                  <td></td>
                </tr>
                <tr>
                  <td><b>Foto</b></td>
                  <td>:</td>
                  <td></td>
                </tr>
                <tr>
                  <td><b>Foto</b></td>
                  <td>:</td>
                  <td></td>
                </tr>
                <tr>
                  <td><b>Foto</b></td>
                  <td>:</td>
                  <td></td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card-text>
        </v-card>
      </v-dialog> -->
    </v-col>
  </v-row>
</template>
<script>
import axios from 'axios'
import {
  mdiPencil,
  mdiDelete,
  mdiEye,
} from '@mdi/js'
import { reactive } from '@vue/composition-api'

const apiRoot = process.env.VUE_APP_APIURL
export default {
  setup() {
    const table1 = reactive({
      headers: [
        { text: 'No', value: 'no', sortable: false },
        { text: 'Foto', value: 'foto', sortable: false },
        { text: 'Nama', value: 'nama_barang', sortable: false },
        { text: 'Harga/ Harga Jual', value: 'harga', sortable: false, align: 'right' },
        { text: 'Stok', value: 'qty', sortable: false, align: 'right' },
        { text: 'Status', value: 'status', sortable: false },
        { text: 'Aksi', value: 'aksi', sortable: false },
      ],
      items: [],
      loading: false,
      pageCount: 0,
      itemsPerPage: 10,
      search: '',
      status: 'SEMUA',
      length_menus: [10, 25, 50],
      statuss: ['READY', 'HABIS', 'HAPUS', 'SEMUA'],
      statuss2: ['ready', 'habis', 'hapus'],
      dialog: false,
      dialogDetail: false,
      editedIndex: -1,
      itemEdit: {
        foto: '',
        previewImage: '',
        id: '',
        nama_barang: '',
        detail: '',
        deskripsi: '',
        harga: 0,
        harga_jual: 0,
        qty: 0,
        status: '',
      },
      itemDefault: {
        foto: '',
        previewImage: '',
        id: '',
        nama_barang: '',
        detail: '',
        deskripsi: '',
        harga: 0,
        harga_jual: 0,
        qty: 0,
        status: '',
      },
      itemSelected: {},
    })
    function getTable1() {
      this.table1.loading = true
      const headers = {
        Authorization: this.token,
      }
      const form = new FormData()
      form.append('page', this.pageTable1)
      form.append('itemsPerPage', this.table1.itemsPerPage)
      form.append('search', this.table1.search)
      form.append('status', this.table1.status)
      axios
        .post(`${apiRoot}/api/Barang/getBarangsTable`, form, { headers })
        .then(response => {
          if (response.data.code === 401) {
            this.$store.dispatch('logout', 'Session berakhir!')
          } else if (response.data.code === 200) {
            this.table1.items = response.data.data
            this.table1.pageCount = response.data.length
          } else {
            alert(response.data.message)
          }
          this.table1.loading = false
        })
        .catch(error => {
          alert(error)
          this.table1.loading = false
        })
    }

    return {
      table1,
      getTable1,
      icons: {
        mdiPencil,
        mdiDelete,
        mdiEye,
      },
    }
  },
  data: () => ({
    pageTable1: 1,
    rules: {
      required: value => !!value || 'Diwajibkan.',
      image: [value => !value || value.size < 2000000 || 'Ukuran tidak boleh lebih dari 2 MB!'],
    },
  }),
  computed: {
    formTitle() {
      return this.table1.editedIndex === -1 ? 'Tambah' : 'Edit'
    },
    token() {
      return this.$store.getters.token
    },
  },
  watch: {
    pageTable1: {
      handler() {
        this.getTable1()
      },
      deep: true,
    },
  },
  created() {
    // this.$store.state.page_title = 'Master & Pengaturan'
    this.getTable1()
  },
  methods: {
    optionChange() {
      this.page = 1
      this.getTable1()
    },
    openDialog() {
      // this.$nextTick(() => {
      this.table1.itemEdit = Object.assign({}, this.table1.itemDefault)
      this.table1.editedIndex = -1
      // })
    },
    openDialogEdit(item) {
      this.table1.editedIndex = this.table1.items.indexOf(item)
      // this.table1.itemEdit = Object.assign({}, item)
      this.table1.itemEdit.id = item.id
      this.table1.itemEdit.nama_barang = item.nama_barang
      this.table1.itemEdit.detail = item.detail
      this.table1.itemEdit.deskripsi = item.deskripsi
      this.table1.itemEdit.harga = item.harga
      this.table1.itemEdit.harga_jual = item.harga_jual
      this.table1.itemEdit.qty = item.qty
      this.table1.itemEdit.status = item.status
      this.table1.itemEdit.previewImage = `data:image/png;base64, ${item.foto}`
      this.table1.itemEdit.foto = ''
      this.table1.dialog = true
    },
    openDialogDelete(item) {
      // this.table1.editedIndex = this.table1.items.indexOf(item)
      // Object.assign(this.table1.itemEdit, item)
      // this.table1.dialogDelete = true
      let conf = confirm('Apakah yakin akan dihapus?')
      if (conf) {
        this.deleteItemConfirmed(item)
      }
    },
    deleteItemConfirmed(item) {
      this.$store.dispatch('loading', true)
      const headers = {
        Authorization: this.token,
      }
      const form = new FormData()
      form.append('id', item.id)
      axios
        .post(`${apiRoot}/api/Barang/hapusedBarang`, form, { headers })
        .then(response => {
          if (response.data.code === 401) {
            this.$store.dispatch('logout', 'Session berakhir!')
          } else if (response.data.code === 200) {
            this.optionChange()
            this.getTable1()
          } else {
            alert(response.data.message)
          }
          this.$store.dispatch('loading', false)
        })
        .catch(error => {
          alert(error)
          this.$store.dispatch('loading', false)
        })
    },
    close() {
      this.table1.dialog = false
      this.$nextTick(() => {
        this.table1.itemEdit = Object.assign({}, this.table1.itemDefault)
        this.table1.editedIndex = -1
      })
    },
    save() {
      if (this.table1.editedIndex > -1) {
        this.$store.dispatch('loading', true)
        const headers = {
          Authorization: this.token,
        }
        const form = new FormData()
        form.append('id', this.table1.itemEdit.id)
        form.append('image', this.table1.itemEdit.foto)
        form.append('nama_barang', this.table1.itemEdit.nama_barang)
        form.append('detail', this.table1.itemEdit.detail)
        form.append('deskripsi', this.table1.itemEdit.deskripsi)
        form.append('harga', this.table1.itemEdit.harga)
        form.append('harga_jual', this.table1.itemEdit.harga_jual)
        form.append('qty', this.table1.itemEdit.qty)
        form.append('status', this.table1.itemEdit.status)
        axios
          .post(`${apiRoot}/api/Barang/editBarang`, form, { headers })
          .then(response => {
            if (response.data.code === 401) {
              this.$store.dispatch('logout', 'Session berakhir!')
            } else if (response.data.code === 200) {
              this.optionChange()
              this.getTable1()
              this.close()
            } else {
              alert(response.data.message)
            }
            this.$store.dispatch('loading', false)
          })
          .catch(error => {
            alert(error)
            this.$store.dispatch('loading', false)
          })
      } else {
        this.$store.dispatch('loading', true)
        const headers = {
          Authorization: this.token,
        }
        const form = new FormData()
        form.append('image', this.table1.itemEdit.foto)
        form.append('nama_barang', this.table1.itemEdit.nama_barang)
        form.append('detail', this.table1.itemEdit.detail)
        form.append('deskripsi', this.table1.itemEdit.deskripsi)
        form.append('harga', this.table1.itemEdit.harga)
        form.append('harga_jual', this.table1.itemEdit.harga_jual)
        form.append('qty', this.table1.itemEdit.qty)
        axios
          .post(`${apiRoot}/api/Barang/addBarang`, form, { headers })
          .then(response => {
            if (response.data.code === 401) {
              this.$store.dispatch('logout', 'Session berakhir!')
            } else if (response.data.code === 200) {
              this.optionChange()
              this.getTable1()
              this.close()
            } else {
              alert(response.data.message)
            }
            this.$store.dispatch('loading', false)
          })
          .catch(error => {
            alert(error)
            this.$store.dispatch('loading', false)
          })
      }
    },
    onFileChange() {
      this.table1.itemEdit.foto = this.$refs.foto.files.item(0)
      this.table1.itemEdit.previewImage = URL.createObjectURL(this.table1.itemEdit.foto)
    },
    getDetail(item) {
      this.table1.loading = true
      const headers = {
        Authorization: this.token,
      }
      const form = new FormData()
      form.append('id_barang', item.id)
      axios
        .post(`${apiRoot}/api/Barang/getDetailBarang`, form, { headers })
        .then(response => {
          if (response.data.code === 401) {
            this.$store.dispatch('logout', 'Session berakhir!')
          } else if (response.data.code === 200) {
            this.table1.itemSelected = Object.assign({}, response.data.data)
          } else {
            alert(response.data.message)
          }
          this.table1.loading = false
        })
        .catch(error => {
          alert(error)
          this.table1.loading = false
        })
    },
  },
}
</script>
